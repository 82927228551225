import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Flex, Box } from '@rebass/grid';
import _get from 'lodash/get';
import Button from '@atoms/Button';
import Label from '@atoms/Label';
import Modal from '@molecules/Modal';
import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';

const StyledCaption = styled(Caption)`
  padding: 0.2em 0.8em 0.5em 0;
`;

const BoxWithRightRuler = styled(Box)`
  border-right: ${(props) => props.theme.cardBoxRuler};
  margin-right: 2em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    border-right: none;
    margin-right: inherit;
  }
`;
const StyledCard = styled(Card)`
  position: relative;
  padding: 2em;
  margin-top : 2em 
  border-left: ${(props) => props.theme.cardLeftBorder};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;
const StyledLabel = styled(Label)`
  color: ${(props) => props.theme.primaryThemeColor};
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${(props) => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;
const StyledButton = styled(Button)`
  font-size: 12px;
`;
const StyledSupportButton = styled(Button)`
  text-align: center;
  font-size: 12px;
  background-color: #ff5a13;
`;

const SupportCard = ({
  title,
  connected,
  mined,
  hidden,
  loading,
  user,
  match: { params },
  tagObjects: { organization },
}) => {
  const [urlModal, setUrlModal] = useState(null);
  const email = _get(user, 'email', '');
  const serviceDeskOrganizationId = _get(
    organization,
    'meta.public.serviceDeskOrganizationId',
    ''
  );

  const handleCreateSupportRequest = () => {
    const url = `https://livion.atlassian.net/servicedesk/customer/portal/3/group/3/create/9?customfield_10805=${params.id}&email=${email}&customfield_10806=${serviceDeskOrganizationId}`;
    window.open(url, '_blank');
  };
  const handleGenerateSupportRequest = () => {
    const url = `https://livion.atlassian.net/servicedesk/customer/portal/3/group/3/create/9?customfield_10805=${params.id}&email=${email}&customfield_10806=${serviceDeskOrganizationId}`;
    setUrlModal(url);
  };
  const handleCopyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
  };
  return (
    <StyledCard title={title} style={{ position: 'relative' }}>
      <StyledCaption>{title}</StyledCaption>
      <StyledFlex flexWrap={['wrap', null]}>
        <Box width={[1, 1, 2 / 3]}>
          <StyledLabel>
            Create a support request when a customer is facing issues with a
            roomrobot device, or share a link to our help center so your
            customer can report the issue himself.
          </StyledLabel>
        </Box>
        <BoxWithRightRuler flex="1 1 auto" />
        <Box width={'210px'}>
          <StyledFlex justifyContent="center" flexDirection="column">
            <StyledButton secondary onClick={handleGenerateSupportRequest}>
              Generate support URL
            </StyledButton>
            <StyledSupportButton onClick={handleCreateSupportRequest}>
              Create support request
            </StyledSupportButton>
          </StyledFlex>
        </Box>
      </StyledFlex>
      {urlModal && (
        <Modal
          header={`Support request URL`}
          submitText="Copy to clipboard"
          onClose={() => setUrlModal(null)}
          onSave={() =>
            handleCopyToClipboard(
              `https://livion.atlassian.net/servicedesk/customer/portal/3/group/3/create/9?customfield_10805=${params.id}&customfield_10806=${serviceDeskOrganizationId}`
            )
          }
          closeColor="#00BCF1"
        >
          {`https://livion.atlassian.net/servicedesk/customer/portal/3/group/3/create/9?customfield_10805=${params.id}&customfield_10806=${serviceDeskOrganizationId}`}
        </Modal>
      )}
    </StyledCard>
  );
};

export default withRouter(SupportCard);
