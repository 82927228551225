import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Login from '@pages/LoginPage';
import Register from '@pages/RegisterPage';
import ForgotPassword from '@pages/ForgotPasswordPage';
import SelectOrganization from '@pages/SelectOrganization';
import ErrorPage from '@pages/ErrorPage';
import { withRouteAuth } from '@contexts/FirebaseContext';
import ScrollToTop from '@atoms/ScrollToTop';
import Orders from '../Orders';
import OrderEdit from '../OrderEdit';
import Devices from '../Devices';
import Device from '../Device';
import Install from '../Install';
import UserEdit from '../UserEdit';
import Users from '../Users';
import Support from '../Support';
import Privacy from '../Privacy';

import Apartment from '../Apartment';
import Maintenance from '../Maintenance';
import DeviceEdit from '../DeviceEdit';
import KeysEdit from '../KeysEdit';
import NotificationCenter from '../NotificationCenter';

import * as routes from '../routes';
import * as roles from '../roles';

const App = () => {
  const authCondition = (authUser) => !!authUser;

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={routes.DEVICES}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Devices)}
          />
          <Route
            exact
            path={routes.ORDERS}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Orders)}
          />
          <Route
            exact
            path={routes.ORDER_CREATE}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(OrderEdit)}
          />
          <Route
            exact
            path={routes.ORDER_EDIT}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(OrderEdit)}
          />
          <Route
            exact
            path={routes.SELECT_ORGANIZATION}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(SelectOrganization)}
          />
          <Route
            exact
            path={`${routes.DEVICES}/:id`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Device)}
          />
          <Route
            exact
            path={`${routes.DEVICES}/:id/edit`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(DeviceEdit)}
          />
          <Route
            exact
            path={`${routes.DEVICES}/:id/maintenance`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION,
              roles.ADMIN
            )(Maintenance)}
          />
          <Route
            exact
            path={`${routes.DEVICES}/:id/apartment/:apartment`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Apartment)}
          />
          <Route
            exact
            path={`${routes.DEVICES}/:id/keys/create`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(KeysEdit)}
          />
          <Route
            exact
            path={`${routes.DEVICES}/:id/keys/:sensorId/edit`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(KeysEdit)}
          />
          <Route
            path={`${routes.INSTALL}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Install)}
          />
          <Route
            path={`${routes.USER_CREATE}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(UserEdit)}
          />
          <Route
            path={`${routes.USER}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(UserEdit)}
          />
          <Route
            path={`${routes.USER_EDIT}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(UserEdit)}
          />
          <Route
            path={`${routes.SUPPORT}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Support)}
          />
          <Route
            path={`${routes.PRIVACY}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Privacy)}
          />
          <Route
            path={`${routes.USERS}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(Users)}
          />
          <Route
            path={`${routes.NOTIFICATION_CENTER}`}
            component={withRouteAuth(
              authCondition,
              routes.LOGIN,
              routes.SELECT_ORGANIZATION
            )(NotificationCenter)}
          />
          <Route
            exact
            path={routes.ERROR}
            component={() => (
              <ErrorPage code="403" message="Forbidden access." />
            )}
          />
          <Route
            exact
            path={routes.LOGIN}
            component={() => <Login toRoute={routes.SELECT_ORGANIZATION} />}
          />
          <Route exact path={routes.REGISTER} component={() => <Register />} />
          <Route
            exact
            path={routes.FORGOT_PASSWORD}
            component={() => <ForgotPassword />}
          />
          <Redirect from="/" to="/devices" />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
